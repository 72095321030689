<template>
    <div>
        <CRow>
            <CCol lg="10">
                <CCard>
                    <CCardHeader>
                        <strong>Ручной ввод</strong>
                    </CCardHeader>
                    <CCardBody>
                        <pre></pre>

                        <CForm @submit.prevent="createData()">
                            <CInput
                                type="hidden"
                                name="action"
                                v-model="form.action"
                                required
                            />
                            <CRow class="mb-4">
                                <CCol md="4">
                                    <label>Выберите режим</label>
                                    <model-list-select :list="timeDatas"
                                                       v-model="form.time_id"
                                                       option-value="value"
                                                       option-text="label"
                                                       placeholder="Выберите режим"
                                                       @input="selectUpdate($event)">
                                    </model-list-select>
                                </CCol>
                                <CCol md="4">
                                    <label>Выберите нитку</label>
                                    <model-list-select :list="lineDatas"
                                                       v-model="form.line_id"
                                                       option-value="value"
                                                       option-text="label"
                                                       placeholder="Выберите нитку">
                                    </model-list-select>
                                </CCol>
                                <CCol md="4">
                                    <label>Выберите дату</label>
                                    <CInput
                                            type="date"
                                            name="action"
                                            v-model="form.created_date"
                                    />
                                </CCol>
                            </CRow>
                            <div v-for="(elem, index) in fields"  :key="elem.val">

                                <CInput
                                        :label="elem.label"
                                        v-model="fields[index].s_value"
                                        required
                                />
                            </div>
                            <br>
                            <CButton type="submit" size="sm" color="primary"><CIcon name="cil-check-circle"/> Обновить</CButton>
                        </CForm>
                    </CCardBody>
                    <CCardFooter>
                    </CCardFooter>
                </CCard>
            </CCol>

        </CRow>
    </div>
</template>

<script>
  import axios from 'axios'
  import {ModelListSelect} from "vue-search-select";
  import 'vue-search-select/dist/VueSearchSelect.css'

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }



  const formatYmd = (date) => {
    return date.toISOString().slice(0, 10)
  };


  let todayDate = new Date();
      todayDate = formatYmd(todayDate);

  export default {
    name: 'Inputdata',
    data: function() {
      return {
        activeGrsId: 0,
        apiDatas: [],
        grsDatas: [],
        regionDatas: [],
        operatorDatas: [],
        lineDatas: [],
        timeDatas: [],
        time_id: "30",
        fields: [

        ],
        form: {
          action: 'update_grs',
          operator_id: "10",
          datas: [],
          time_id: "",
          line_id: "",
          created_date: "",
        },
      }
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          if (route.query && route.query.page) {
            this.activePage = Number(route.query.page)
          }
        }
      }
    },
    methods: {
      createData() {

        let bodyFormData = new FormData();

        todayDate = this.form.created_date !== "" ? this.form.created_date : todayDate;

        todayDate = todayDate + " 02:00:00";

          bodyFormData.append('action', "create_data");
        bodyFormData.append('id', this.activeGrsId);
        bodyFormData.append('operator_id', this.form.operator_id);
        bodyFormData.append('line_id', this.form.line_id);
        bodyFormData.append('time_id', this.form.time_id);
        bodyFormData.append('created_date', todayDate);

        this.form.datas = [];

        this.fields.forEach((e) => {
          this.form.datas.push([
            `${e.id}::${e.s_value}`
           ]);
        })

        bodyFormData.append('datas', this.form.datas.join());

        axios({
          method: "post",
          url: `${axios.defaults.baseURL}`,
          data: bodyFormData
        })
          .then((r) => {

              this.form.line_id = "";
              this.form.time_id = "";
              this.form.created_date = "";
              this.fields.forEach((el) => {
                  el.s_value = ""
              })
            // this.$router.push({path: `/inputdata`})
            //Perform Success Action
            // this.getDataFromApi()
          })
          .catch((error) => {
            console.error(error)
            // error.response.status Check status code
          }).finally(() => {
          // this.getDataFromApi()
        });
      },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      },
      selectUpdate (param) {
        // TODO: убрать обращение к серверу на каждое изменение селекта
        this.time_id = param
        axios
          .get(`${axios.defaults.baseURL}?action=get_data_types_web&time_id=${this.time_id}`)
          .then((response) => {
            this.fields = [];
            response.data.map((el) => {
              this.fields.push({
                id: el.id,
                label: el.name,
                s_value: ''
              })
            });
          })
      }

    },
    created() {
      this.$watch(
        () => this.$route.params
      )
    },
    mounted() {
      if (this.$route.meta.gid === undefined) {
        this.activeGrsId = getCookie('activeGrsId');
      } else {
        document.cookie = `activeGrsId=${this.$route.meta.gid}`
        this.activeGrsId = this.$route.meta.gid;
      }

      axios
        .get(`${axios.defaults.baseURL}?action=get_grs&id=${this.activeGrsId}`)
        .then((response) => {
            if (response.data.length > 0) {
                this.form.grs_name = response.data[0].name
                this.form.region_id = response.data[0].region_id
                this.form.operator_id = response.data[0].operator_id
            }
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_times`)
        .then((response) => {
          response.data.map((el) => {
            this.timeDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_all_lines`)
        .then((response) => {
          response.data.map((el) => {
            this.lineDatas.push({
              value: el.id,
              label: el.name
            })
          });
        })
      axios
        .get(`${axios.defaults.baseURL}?action=get_data_types&time_id=${this.time_id}`)
        .then((response) => {
          response.data.map((el) => {
            this.fields.push({
              id: el.id,
              label: el.name,
              s_value: ''
            })
          });
        })
    },
    components: {
      ModelListSelect
    }
  }
</script>
